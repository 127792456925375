
import { computed, defineComponent, onMounted, ref } from "vue";
import { autoLogin } from "@/core/services/AutoLogin";
import ApiService from "@/core/services/ApiService";
import CrudUserModal from "@/views/pages/Admin/users/components/CrudUserModal.vue";
import CreateNewFinancialLog from "@/views/pages/Admin/transactions/components/CreateNewFinancialLog.vue";
import JwtService from "@/core/services/JwtService";
import { statusColor, translate } from "@/core/services/HelpersFun";
import { ElNotification } from "element-plus/es";
import UserAvatar from "@/components/UserAvatar.vue";
import DatePicker from "vue3-persian-datetime-picker";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Countries } from "@/store/pinia/Countries";
import { storeToRefs } from "pinia";
import { AllBots } from "@/store/pinia/AllBots";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-list-management-11",
  components: {
    CreateNewFinancialLog,
    CrudUserModal,
    UserAvatar,
    DatePicker,
  },
  props: {
    widgetClasses: String,
    onlineSince: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(true);
    const list = ref([]);
    const total_purchase_amount = ref();
    const pagination = ref();
    const page = ref(1);
    let rowCounter = 0;
    const selectedUserId = ref(1);
    const selectedUser = ref();
    const editUser = ref();
    const route = useRoute();
    const store = useStore();

    const countriesHolder = Countries();
    const { countriesList } = storeToRefs(countriesHolder);
    const { getCountries } = countriesHolder;

    // bots
    const AllBotsHolder = AllBots();
    const { fetchBotList } = AllBotsHolder;

    const filterData = ref({
      has_active_bot: 0,
      has_enable_message: 0,
      buy_package: 0,
      has_expired_bot: 0,
      has_enable_secretary: 0,
      registered_to: "",
      registered_from: "",
      type: "",
      q: "",
      with_commission: true,
      sort: "",
      page: 1,
      use_discount: "",
      has_enable_api: "",
      buy_packages_count: "",
      use_discount_id: "",
      buy_from_date: "",
      buy_to_date: "",
      without_package: "",
      only_free_packages: "",
      online_since: props.onlineSince,
      with_country: true,
      country_id: "",
    });
    const getItems = (data: any = {}) => {
      loading.value = true;
      data.page = page.value;
      ApiService.query("admin/user", { params: filterData.value })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.users.data;
          total_purchase_amount.value = data.data.total_purchase_amount;
          pagination.value = data.data.users.pagination;
          rowCounter =
            parseInt(pagination.value.total) -
            pagination.value.per_page * (pagination.value.current_page - 1);
        })
        .catch(() => {
          loading.value = false;
          ElNotification({
            title: "خطا",
            message: "خطا در بارگزاری لیست کاربران",
            type: "error",
          });
        });
    };

    const reset = () => {
      filterData.value = {
        has_active_bot: 0,
        has_enable_message: 0,
        buy_package: 0,
        has_expired_bot: 0,
        has_enable_secretary: 0,
        registered_to: "",
        registered_from: "",
        type: "",
        q: "",
        with_commission: true,
        sort: "",
        page: 1,
        use_discount: "",
        has_enable_api: "",
        buy_packages_count: "",
        use_discount_id: "",
        buy_from_date: "",
        buy_to_date: "",
        without_package: "",
        only_free_packages: "",
        online_since: props.onlineSince,
        with_country: true,
        country_id: "",
      };
      getItems();
    };

    // const connectToSocket = async () => {
    //   let user_info = JSON.parse(
    //     String(window.localStorage.getItem("user_info"))
    //   );
    //   if (
    //     user_info.type === "super_admin" ||
    //     user_info.type === "admin" ||
    //     user_info.type === "operator"
    //   ) {
    //     let userId = JwtService.getUserId();
    //     if (JwtService.getUserId()) {
    //       window.Echo.private("admins").listen(".reload_users", (e) => {
    //         getItems();
    //       });
    //     }
    //   }
    // };

    const delete_ids = ref();
    const pulling_data = ref();
    const pulling = () => {
      if (route.name === "UsersListTable") {
        console.log("user pulling");
        ApiService.query("/whats-up", {
          params: { delete_ids: delete_ids.value },
        })
          .then((data) => {
            if (JSON.parse(pulling_data.value).polling_mode === true) {
              console.log(data.data.data);
              let res = data.data.data.operations;
              delete_ids.value = [];

              for (let i = 0; i < res.length; i++) {
                // delete_ids.value.push(res[i].id);

                if (res[i].type === "reload_users") {
                  delete_ids.value.push(res[i].id);
                  getItems();
                }
                if (res[i].type === "reload_bots") {
                  delete_ids.value.push(res[i].id);
                  fetchBotList();
                }
                if (res[i].type === "refresh_tickets") {
                  delete_ids.value.push(res[i].id);
                  store.dispatch(Actions.VERIFY_AUTH);
                }
                if (res[i].type === "refresh_browser") {
                  delete_ids.value.push(res[i].id);
                  window.location.reload();
                }
              }
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    const permission = ref([]);

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
      }, 3000);

      getItems();
      getCountries();
      if (route.name === "UsersListTable")
        setCurrentPageBreadcrumbs("لیست کاربران", [translate("management")]);

      pulling_data.value = localStorage.getItem("user_info");
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   setInterval(() => {
      //     pulling();
      //   }, 8000);
      // } else {
      //   connectToSocket();
      // }

      if (JSON.parse(pulling_data.value).send_polling_requests === true) {
        setInterval(() => {
          pulling();
        }, 8000);
      }

      // if (JSON.parse(pulling_data.value).polling_mode === false) {
      //   connectToSocket();
      // }
    });

    const rowIndex = () => {
      return rowCounter--;
    };

    const handleCurrentChange = (val: number) => {
      filterData.value.page = val;
      getItems();
    };

    const secretaryStatus = (bots) => {
      let active = bots.filter((item) => item.status === "ready");
      let inactive = bots.filter((item) => item.status !== "ready");
      return {
        active: active.length ?? 0,
        inactive: inactive.length ?? 0,
      };
    };

    const getType = (type) => {
      switch (type) {
        case "admin":
          return "مدیر";
        case "super_admin":
          return "مدیر کل";
        case "user":
          return "کاربر";
      }
    };

    const requestAutoLogin = (id) => {
      autoLogin(id);
    };

    const sortBy = (q) => {
      filterData.value.sort = q;
      getItems();
    };

    const excelLink = computed(() => {
      let params = { ...filterData.value };
      var queryString = Object.keys(filterData.value)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&");

      return (
        process.env.VUE_APP_BACK_URL +
        `users/download?api_token=${JwtService.getToken()}&${queryString}`
      );
    });

    const defFilter = ref(true);
    const sort = (item) => {
      list.value.sort((a, b) => {
        if (defFilter.value === true) {
          if (a[item] > b[item]) return 1;
          else if (a[item] < b[item]) return -1;
          else return 0;
        }
        if (defFilter.value === false) {
          if (a[item] > b[item]) return -1;
          else if (a[item] < b[item]) return 1;
          else return 0;
        }
        return 0;
      });
      defFilter.value = !defFilter.value;
    };

    return {
      reset,
      list,
      getItems,
      loading,
      pagination,
      handleCurrentChange,
      getType,
      requestAutoLogin,
      selectedUserId,
      rowIndex,
      selectedUser,
      excelLink,
      editUser,
      statusColor,
      translate,
      secretaryStatus,
      sort,
      filterData,
      sortBy,
      route,
      countriesList,
      permission,
      total_purchase_amount,
    };
  },
});
